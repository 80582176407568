import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><strong parentName="p"><em parentName="strong">{`*`}{`We will have our free 4th of July class today at The Ville at
10:00am.  This will be our only class today, all other classes and open
gym will be cancelled so come out and join in the fun!`}</em></strong></p>
    <p><strong parentName="p"><em parentName="strong">{`We will have beginners to advanced levels of the following
workout:`}</em></strong></p>
    <p><strong parentName="p"><em parentName="strong">{`2017 4th Of July WOD`}</em></strong></p>
    <p><em parentName="p">{`Round 1: `}</em></p>
    <p><strong parentName="p"><em parentName="strong">{`1`}</em></strong>{`00ft Bear Crawl`}</p>
    <p><strong parentName="p"><em parentName="strong">{`7`}</em></strong>{` Box Jumps (24/20)`}</p>
    <p><strong parentName="p"><em parentName="strong">{`76`}</em></strong>{`0 Meter Row`}</p>
    <p><em parentName="p">{`Round 2: `}</em></p>
    <p><strong parentName="p"><em parentName="strong">{`1`}</em></strong>{`00ft Burpee Broad Jump`}</p>
    <p><strong parentName="p"><em parentName="strong">{`7`}</em></strong>{` Pullups`}</p>
    <p><strong parentName="p"><em parentName="strong">{`76`}</em></strong>{` Pushups`}</p>
    <p><em parentName="p">{`Round 3:`}</em></p>
    <p><strong parentName="p"><em parentName="strong">{`1`}</em></strong>{`00ft Walking Lunge`}</p>
    <p><strong parentName="p"><em parentName="strong">{`7`}</em></strong>{` Box Jumps`}</p>
    <p><strong parentName="p"><em parentName="strong">{`76`}</em></strong>{` Double Unders`}</p>
    <p><em parentName="p">{`Round 4:`}</em></p>
    <p><strong parentName="p"><em parentName="strong">{`1`}</em></strong>{`00ft Crab Crawl`}</p>
    <p><strong parentName="p"><em parentName="strong">{`7`}</em></strong>{` Pullups`}</p>
    <p><strong parentName="p"><em parentName="strong">{`76`}</em></strong>{` Squats`}</p>
    <p>{`For time.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      